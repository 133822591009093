window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});


document.addEventListener("DOMContentLoaded", function() {
    // Function to set both TrustedForm URL and ID
    function setTrustedFormValues(retries = 10) {
        // Get the TrustedForm dynamic field
        var trustedFormCertUrlField = document.getElementById('xxTrustedFormCertUrl_0');
        // Get the Heyflow hidden fields for URL and ID
        var heyflowUrlField = document.getElementById('input-88e86d7e');  // For TrustedForm URL
        var heyflowIdField = document.getElementById('input-388220b4');   // For TrustedForm ID

        // Check if the fields are available
        if (trustedFormCertUrlField && heyflowUrlField && heyflowIdField) {
            var trustedFormCertUrl = trustedFormCertUrlField.value;

            // Ensure the TrustedForm URL is not empty
            if (trustedFormCertUrl) {
                // Set the URL in the corresponding Heyflow field
                heyflowUrlField.value = trustedFormCertUrl;

                // Extract the TrustedForm ID from the URL and set it in the Heyflow ID field
                var trustedFormId = trustedFormCertUrl.split('/').pop();
                heyflowIdField.value = trustedFormId;

                console.log('TrustedForm URL successfully set:', trustedFormCertUrl);
                console.log('TrustedForm ID successfully set:', trustedFormId);
            } else {
                console.error('TrustedForm URL is empty.');
            }
        } else if (retries > 0) {
            // Retry if fields are not available, with a small delay
            console.warn('Fields not found. Retrying...');
            setTimeout(function() {
                setTrustedFormValues(retries - 1);
            }, 500); // Retry every 500ms
        } else {
            console.error('Failed to set TrustedForm URL and ID after retries.');
        }
    }

    // Call the function once the DOM is loaded
    setTrustedFormValues();
});
